import config from "../../../public/config.json"

const getProductImageUrl = (productImage) => {
    // let imagePublicUrl = '';

    // if (config.api.dev_mode === false) {
    //     imagePublicUrl = `https://api.${location.hostname}/ow/assets/${productImage.image}`
    // } else {
    //     imagePublicUrl = `${config.api.url}/ow/assets/${productImage.image}`
    // }
    
    // return productImage.url == 0 ? imagePublicUrl : productImage.image
    return productImage;
}

export { getProductImageUrl };